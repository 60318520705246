var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"mainnav"},[(_vm.viewIsMobile)?_c('Logo'):_vm._e(),_vm._v(" "),(!_vm.isFoodsaver)?_c('Link',{attrs:{"title":_vm.$i18n('foodsaver.upgrade.FOODSAVER'),"icon":"fa-hands-helping","href":_vm.$url('quiz_foodsaver')}}):_vm._e(),_vm._v(" "),(_vm.isFoodsaver && !_vm.viewIsMobile)?_c('NavRegions'):_vm._e(),_vm._v(" "),(_vm.isFoodsaver && !_vm.viewIsMobile)?_c('NavGroups'):_vm._e(),_vm._v(" "),(_vm.isFoodsaver)?_c('NavStores'):_vm._e(),_vm._v(" "),_c('NavBaskets'),_vm._v(" "),(_vm.viewIsMobile)?_c('NavConversations'):_vm._e(),_vm._v(" "),(_vm.viewIsMobile)?_c('NavNotifications'):_vm._e(),_vm._v(" "),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"},scopedSlots:_vm._u([{key:"default",fn:function({ expanded }){return [(_vm.getMailUnreadCount)?_c('span',{staticClass:"badge badge-danger",class:{
          'onlyNine': String(_vm.getMailUnreadCount).length === 1,
          'overNinetyNine': String(_vm.getMailUnreadCount).length > 2,
        },domProps:{"textContent":_vm._s(_vm.getMailUnreadCount)}}):_vm._e(),_vm._v(" "),_c('i',{staticClass:"fas",class:{
          'fa-bars': !expanded,
          'fa-times': expanded,
        }})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }