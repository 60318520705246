import './partials/Footer/footer.js'
import './partials/Navigation/navigation.js'

/**
 * The Javascript loader is at `src/Modules` located.
 *
 * Already to VUE translated:
 * --------------------------------------------------
 * Index
 * Dashboard
 */
