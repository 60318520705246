import { render, staticRenderFns } from "./NavNotificationsEntry.vue?vue&type=template&id=530f0811&scoped=true&"
import script from "./NavNotificationsEntry.vue?vue&type=script&lang=js&"
export * from "./NavNotificationsEntry.vue?vue&type=script&lang=js&"
import style0 from "./NavNotificationsEntry.vue?vue&type=style&index=0&id=530f0811&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530f0811",
  null
  
)

export default component.exports