import { render, staticRenderFns } from "./LanguageChooserModal.vue?vue&type=template&id=502b5476&"
import script from "./LanguageChooserModal.vue?vue&type=script&lang=js&"
export * from "./LanguageChooserModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports