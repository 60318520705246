var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":_vm.$url('store', _vm.entry.id),"role":"menuitem"}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(_vm.pickupStringStatus),expression:"pickupStringStatus"}],staticClass:"icon-subnav fas fa-circle",class:{
      'text-transparent': !_vm.entry.pickupStatus,
      'text-primary': _vm.entry.pickupStatus === 1,
      'text-warning': _vm.entry.pickupStatus === 2,
      'text-danger': _vm.entry.pickupStatus === 3,
      'icon--help': _vm.entry.pickupStatus > 0,
    }}),_vm._v("\n  "+_vm._s(_vm.entry.name)+"\n")])
}
var staticRenderFns = []

export { render, staticRenderFns }